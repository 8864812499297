.dln-kenwood {
    & .dln-createShippingAddress {
        @media only screen and (max-width:599px) {
            padding: 16px 20px 40px !important;
            & .dln-divider ~ .dln-inputs {
                margin-bottom: 0;
            }
            & .dln-createShippingAddress__form {
                & > .dln-select {
                    width: 100%;
                    max-width: 100%;
                }
                & .dln-inputs {
                    width: 100%;
                    margin: 0 0 32px;
                }
                & .dln-btn {
                    width: 100%;
                    margin: 0 0 16px 0;

                    &:nth-of-type(even) {
                        margin: 0 0;
                    }
                }
            }
        }
        padding: 16px 40px 40px !important;

        &__fillBtn {
            margin-bottom: 10px;
        }

        & .dln-popup {
            &__head {
                width: 100% !important;
                padding-bottom: 25px !important;
                padding-top: 19px;
                border-bottom: 1px solid var(--ui-02);
                margin: 0 !important;

                &Title {
                    font-size: 18px !important;
                    line-height: 22px !important;
                }
            }

            & .dln-popup__headTitle {
                display: block;
                width: 100%;
                max-width: 100%;
                line-height: 22px;
                font-size: 18px;
                text-align: left;
                padding-bottom: 0;
                margin: 0;
            }
            & .dln-btnIcon {
                position: absolute;
                top: -20px;
                right: -20px;

                & .dln-icn {
                    @include definePseudoElems;
                    text-indent: 999px;
                    width: 22px;
                    height: 22px;
                    font-size: 22px;
                    overflow: hidden;

                    &::before {
                        @include fontIcon;
                        text-indent: 0;
                        content: 'J';
                        color: $brand-01;
                    }
                }
            }
            & .dln-popup__headLine {
                display: none;
            }
        }
        & .dln-createShippingAddress__content {
            margin-top: 17px;
            padding: 0;
        }
        & .dln-createShippingAddress__form {
            & .dln-inputs {
                //margin: 0 16px 30px 0;
            }

            .row {
                width: 100%;

                @media only screen and (max-width:599px) {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    row-gap: 30px;
                }

                .second {
                    display: flex;

                    .dln-tooltip {
                        top: 10px;
                    }
                }
            }

            & > .dln-select {
                margin: 0 16px 30px 0;

                & + .dln-select {
                    margin: 0 0 30px 0;
                }
            }
            & .dln-chips {
                background-color: $ui-03;
                margin: 0 0 40px;

                & + .dln-inputs {
                    margin: 0 16px 30px 0;
                    &:nth-child(odd) {
                        margin: 0 0 30px;
                    }
                }
                &__wrapper {
                    & .dln-icn {
                        align-self: flex-start;
                    }
                }
            }
            & > .dln-checkbox {
                margin: 24px 0 0;

                &__wrap {
                    .dln-checkbox__inputPlaceholder {
                        width: 24px;
                        height: 24px;
                        border-color: var(--ui-12);
                    }
                }
            }
            & .dln-checkout__paragraph {
                margin: 24px 0;
            }
            & .dln-btn:not(.dln-btn--vatnumber_check) {
                width: calc(50% - 8px);
                margin: 0 16px 0 0;

                &:nth-of-type(even) {
                    margin: 0 0;
                }
                &--stroked {
                    height: 46px;
                    border: 1px solid var(--brand-05);
                    color: $brand-04;
                    text-transform: lowercase;
                    text-decoration: none;
                    margin: 0 auto 0 0;
                    width: 195px;
                    padding: 0;
                    font-size: 18px;

                    &::first-letter {
                        text-transform: uppercase;
                    }
                }
                &:not(.dln-btn--stroked):not(.dln-btn--vatnumber_check) {
                    padding: 0;
                    width: 195px;
                    text-transform: capitalize;
                    font-size: 18px;
                    line-height: 24px;
                    @media only screen and (max-width:599px) {
                        max-width: none;
                        width: 100%;
                        margin: 20px 0 0;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:599px) {
    .dln-kenwood {
        & .dln-popup {
            & .dln-popup__content {
                & .dln-popup__wrapper {
                    & .dln-createShippingAddress {
                        & .dln-popup__head {
                            margin: 0;

                            &Title {
                                order: 2;
                                padding-top: 9px
                            }
                            & > .dln-btnIcon--close {
                                order: 1;
                                //margin-bottom: 40px;
                                position: absolute;
                                top: 27px;
                            }
                            &Line {
                                display: none !important;
                            }
                        }
                        &__form {
                            & .dln-inputs,
                            & .dln-inputs.dln-inputs--provinceState {
                                margin: 0;
                            }
                            .dln-divider {
                                display: none;
                            }
                            & > .dln-select {
                                margin: 0 0 40px 0;
                            }
                        }
                    }
                }
            }
        }
        .dln-createShippingAddress {
            .dln-createShippingAddress__form {
                .dln-btn {
                    width: 100%;
                    margin-bottom: 20px;

                    &--vatnumber_check {
                        margin: 0 0 30px 0;
                    }
                }
            }
        }
        & .dln-alert--createShippingAddress {
            padding: 0 20px 40px 20px;

            & .dln-btnIcon--close {
                right: 0 !important;
            }
            & .dln-createShippingAddress__form {
                & .dln-select,
                & .dln-inputs {
                    margin-right: 0 !important;
                }
            }
            & .dln-chips {
                border: 1px solid $support-04;
                padding: 16px !important;

                & .dln-icn {
                    color: $support-04 !important;
                }
                &__content span {
                    font-size: 12px;

                    & a {
                        @include fontBold;
                    }
                }
            }
        }
    }
}
