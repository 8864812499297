.dln-kenwood {
	.dln-profile {
		&.dln-account {
			& .dln-wishProd {
					display: flex;

					@media screen and (min-width: 599px) {
						flex-direction: column;
						width: calc(50% -5px);
					}

					@media screen and (min-width: 1200px) {
						flex-direction: unset;

						&__priceWrap--outOfStock {
							left: 100px;
						}
					}
					&__imgLink {
						width: 100%;
						height: 146px;
						margin: 0;

						@media screen and (min-width:1200px) {
							width: 182px;
							margin-right: 20px;
						}
					}
					&__data {
						display: flex;
						align-items: flex-start;
						height: auto;
						margin-top: 8px;
						min-height: 114px;

						@media screen and (min-width: 599px) {
							&__imgWrap {
								height: 146px;
							}
						}

						@media screen and (min-width: 1200px) {
							width: calc(100% - 202px);
						}
						& .dln-btn--action-button {
							text-align: center;
							margin: 15px 0 0;
						}
					}
					&__name {
						text-align: left;
						padding-bottom: 10px;
						margin: 0;
						font-size: 14px;
					}
					&__priceWrap:not(.dln-wishProd__priceWrap--outOfStock) {
						@media screen and (min-width:599px) {
							text-align: left;
						}
					}
					& .dln-btnTextIcon {
							margin: 0;
					}
					& .dln-btn--action-button {
							text-align: left;
							margin: 0;
					}
				}
				& .dln-profile__section {
						&Head {
							align-items: flex-end;
							margin: 0 8px 14px 8px;

							.dln-btn--link {
								@include fontBold;
								text-decoration: underline;
								margin-bottom: 8px;
							}

						}
				}
				& .dln-recipe {
						&__title {
								font-weight: bold;
						}
				}
				.dln-wrapper--carousel {
					max-width: 1560px;
					margin: 0 auto;
					padding-right: 40px;
					padding-left: 40px;
					.dln-carousel {
							padding: 0;
					}
				}
				@media only screen and (max-width:599px) {
					& .dln-wishProd {
						display: flex;
						flex-direction: column;
						width: calc(50% - 5px);

						&__imgLink {
							width: 100%;
							height: 146px;
							margin: 0;
						}
						&__imgWrap {
							height: 146px;
						}
						&__name {
							text-align: center;
						}
						&__priceWrap {
							text-align: center;
							&--outOfStock {
								top: 55px;
							}
						}
						& .dln-btnTextIcon {
								max-width: 100%;
								margin: 30px auto 0;
						}
						& .dln-btn--action-button {
							text-align: center;
							margin: 15px auto;
						}
					}
					& .dln-wishProd__data {
						width: 100%;
						margin-top: 8px;
						text-align: center;
					}
					&--01 {
						& .dln-profile__sectionHead {
							&Title {
									&--smallMobile {
										font-size: 28px;
										line-height: 28px;
									}
							}
							& .dln-btn--link {
								text-decoration: underline;
							}
						}
					}
					&__section {
						.dln-recipe {
								margin-bottom: 20px;
						}
					}
					.dln-wrapper--carousel {
						max-width: 1520px;
						padding: 0 20px;
					}
			}
		}
	}
}