$prefix: dln;

.#{$prefix}-popup {
    display: none;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    background: rgba(0, 0, 0, .8);
    opacity: 0;
    transition: opacity 0.2s;
    will-change: opacity;
    overflow-x: hidden;
    overflow-y: auto;
    pointer-events: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    transition: opacity 0.3s ease .1s;
    z-index: 1001;

    &__content {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: flex-start;
        width: 100%;
        max-width: 416px;
        height: auto;
        background-color: $brand-03;
        margin: auto 0;
        overflow: initial;
        position: relative;
        z-index: 201;
        opacity: 0;
        transition: all .3s;
        transition: opacity 0.3s ease;

        @media (-ms-high-contrast: none) {
            align-self: flex-start;
        }
    }
    &__wrapper {
        display: flex;
        position: relative;
        flex-wrap: wrap;
        width: 100%;
        height: auto;
        background-color: $brand-03;
        /* padding-top: 10px; */
        >div:first-child {
            width: 100%;
        }
    }
    &--visible {
        opacity: 1;
        transition: opacity 0.4s;
    }
    &--open {
        pointer-events: initial;
    }
    .dln-btnIcon--close {
        .dln-btnIcon__wrap,
        .dln-btnIcon__icon {
            pointer-events: none;
        }
    }
    &__closeBtn {
        position: absolute;
        width: 20px;
        height: 20px;
        color: $brand-02;
        z-index: 9;
        right: 20px;
        top: 10px;
        pointer-events: auto;
        & .dln-icn {
            font-size: 20px;
            pointer-events: none;
            width: 20px;
            height: 20px;
            &:before {
                content: 'J';
            }
        }
    }
    &--circleClose {
        .#{$prefix}-popup__closeBtn {
            & .dln-icn {
                font-size: 20px;
                pointer-events: none;
                &:before {
                    content: 'g';
                    color: $ui-04;
                }
            }
        }
    }
    &--alert {
        background-color: transparent;
        width: auto;
        max-width: 585px;
        height: auto;
        min-height: auto;
        position: fixed;
        right: 30px;
        left: auto;
        top: 30px;
        bottom: auto;
        .dln-popup__wrapper {
            .dln-popup__closeBtn {
                right: 40px;
                top: 22px;
            }
        }
    }


    &--fullsize-desktop-mobile {
        padding: 0 !important;

        .dln-popup__content {
            max-width: initial !important;
            border-radius: 0;
            height: 100%;

            @media (-ms-high-contrast: none) {
                max-width: 100% !important;
            }
        }
    }
}

body {
    &.dln-popup--open {
        overflow: hidden;
    }
    &.dln-popup--openAlert {
        overflow: auto;
    }
}

/* padding */
.#{$prefix}-popup {
    padding: 20px 20px 20px 20px;
}

/* border-radius */
.#{$prefix}-popup__content,
.#{$prefix}-popup__wrapper {
    border-radius: 5px 5px 5px 5px;
}

/* Desktop alignment */
.#{$prefix}-popup--dTop {
    & .#{$prefix}-popup__content {
        margin: 0 0 auto 0;
    }
}
.#{$prefix}-popup--dCenter {
    & .#{$prefix}-popup__content {
        margin: auto 0 auto 0;
    }
}
.#{$prefix}-popup--dBottom {
    & .#{$prefix}-popup__content {
        margin: auto 0 0 0;
    }
}

/* Scroll origin */
.#{$prefix}-popup--scrollModal {
    & .#{$prefix}-popup__content {
        // max-height: calc(100vh - 64px);
        overflow: hidden;
    }
    & .#{$prefix}-popup__wrapper {
        // max-height: calc(100vh - 64px);
        overflow: hidden;
        overflow-y: auto;
    }
}

/* Animation */
&.#{$prefix}-popup--visible {
    & .#{$prefix}-popup__content {
        transition: opacity 0.3s ease .2s;
        opacity: 1;
    }
}
.#{$prefix}-popup--fadeIn {
    //
}
.#{$prefix}-popup--fadeInUp,
.#{$prefix}-popup--fadeInDown,
.#{$prefix}-popup--zoomIn {
    & .#{$prefix}-popup__content {
        transition: transform 0.3s ease, opacity 0.3s ease;
        will-change: transform;
    }
    &.#{$prefix}-popup--open {
        & .#{$prefix}-popup__content {
            transition: transform 0.3s ease .1s, opacity 0.3s ease;
        }
    }
}

.#{$prefix}-popup--fadeInUp {
    & .#{$prefix}-popup__content {
        transform: translateY(32px);
    }
    &.#{$prefix}-popup--open {
        & .#{$prefix}-popup__content {
            transform: translateY(0);
        }
    }
}
.#{$prefix}-popup--fadeInDown {
    & .#{$prefix}-popup__content {
        transform: translateY(-32px);
    }
    &.#{$prefix}-popup--open {
        & .#{$prefix}-popup__content {
            transform: translateY(0);
        }
    }
}
.#{$prefix}-popup--zoomIn {
    & .#{$prefix}-popup__content {
        transform: scale(0.96, 0.96);
    }
    &.#{$prefix}-popup--open {
        & .#{$prefix}-popup__content {
            transform: scale(1, 1);
        }
    }
}

.ma1.modal-template {
    width: 100%;
    height: auto;
    padding: 32px;
    box-sizing: border-box;
}
.modal-template>button {
    margin-bottom: 40px;
    -moz-apperance: none;
    width: 120px;
    height: 40px;
    font-size: 14px;
    color: white;
    border: none;
    border-radius: 5px;
    background: black;
    -webkit-appearance: none;
    cursor: pointer;
}
