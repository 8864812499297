@function z($layer) {
  @if not map-has-key($z-layers, $layer) {
    @warn "No z-index found in $z-layers map for `#{$layer}`. Property omitted.";
  }
  @return map-get($z-layers, $layer);
}

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@function toRem($value) {
  $stripValue: strip-unit($value);
  $remValue: (($stripValue / 16 * 90) / 100) + rem;
  @if $stripValue <= 12 {
    $remValue: ($stripValue / 16) + rem;
  }
  @return $remValue;
}

@function REM($value) {
  $stripValue: strip-unit($value);
  $remValue: ($stripValue / 16) + rem;
  @return $remValue;
}
